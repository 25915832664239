<template>
    <div>
        <div class="flex flex-1 h-full flex-col px-4">
            <div class="flex w-full flex-wrap">
                <div v-for="(category) in sortedCategories" :key="category.name" class="category-item w-1/2 flex items-top mb-8">
                    <icon :name="category.point_of_interest_category_icon" class="h-6 w-6 mr-1 text-gray-800"></icon>
                    <span>{{ category.point_of_interest_category_name }}</span>
                    <div @click="removeCategory(category)" class="remove-category flex items-center rounded-full cursor-pointer bg-red-100 text-red-500 border border-red-500 ml-2 p-1 h-6">
                        <icon name="close" class="h-3 w-3" />
                    </div>
                </div>
                <div class="w-1/2">
                    <div v-if="!addNew" class="text-link text-link_bold mb-2 flex items-center cursor-pointer"  @click="addNew = true">
                        <icon name="plus" class="h-4 w-4 inline mr-2 cursor-pointer"></icon> add new category
                    </div>
                    <div v-if="addNew" class="flex flex-col">
                        <div class="flex">
                            <icon-picker v-model="newCategory.point_of_interest_category_icon" :icons="icons"></icon-picker>
                            <div class="w-full">
                              <text-input
                                class="ml-2"
                                v-model="newCategory.point_of_interest_category_name"
                                placeholder="Category name..."
                              />
                              <div v-if="hasError" class="w-full ml-2 bg-red-100 border p-1 border-red-400 text-sm text-red-700">
                                   category already exists
                              </div>
                            </div>
                           
                        </div>
                        
                        <div class="flex justify-between mt-2">
                            <button class="btn-primary btn-transparent mr-2" @click="addNew = false">cancel</button>
                            <button class="btn-primary" @click="addNewCategory">add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Icon from "@/components/ui/Icon";
    import {mapActions, mapGetters} from "vuex";
    import IconPicker from "@/components/ui/IconPicker";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import TextInput from '@/components/ui/TextInput';
    import NotifyMixin from "@/mixins/NotifyMixin";

    export default {
        components: {IconPicker, Icon, TextInput },
        mixins: [ ModalNavigation, NotifyMixin ],
        data: () => {
            return {
                website: {},
                categories: [],
                newCategory: {
                    point_of_interest_category_icon: null
                },
                addNew: false,
                poiCategoriesAlertId: 'poi-categories-alert',
                icons: [
                    { name: 'bag' },
                    { name: 'balloon' },
                    { name: 'bed' },
                    { name: 'beer' },
                    { name: 'bike' },
                    { name: 'boxing' },
                    { name: 'bus' },
                    { name: 'cart' },
                    { name: 'church' },
                    { name: 'cinema' },
                    { name: 'coffee2' },
                    { name: 'dessert' },
                    { name: 'dog' },
                    { name: 'fastfood' },
                    { name: 'food' },
                    { name: 'gas' },
                    { name: 'grill' },
                    { name: 'gym' },
                    { name: 'hospital' },
                    { name: 'hotel' },
                    { name: 'plane' },
                    { name: 'pool1' },
                    { name: 'pool2' },
                    { name: 'running' },
                    { name: 'shopping' },
                    { name: 'school' },
                    { name: 'tent' },
                    { name: 'train' },
                    { name: 'tree1' },
                    { name: 'tree2' },
                    { name: 'trees' }
                ],
                hasError: false
            }
        },
        computed: {
            ...mapGetters({
                getCategories: 'points_of_interest/getPointsOfInterestCategories',
                getWebsiteInfo: 'website_info/getWebsiteInfo'
            }),
            sortedCategories: function() {
                function compare(a,b) {
                        if (a.point_of_interest_category_name < b.point_of_interest_category_name) {
                            return -1
                        }
                        if (a.point_of_interest_category_name > b.point_of_interest_category_name) {
                            return 1
                        }
                        return 0
                }
                return this.categories.slice().sort(compare);
            },
        },
        methods: {
            ...mapActions({
                updatePointsOfInterestCategoriesInStore : 'points_of_interest/loadPointsOfInterestCategories',
                notifySuccess: 'alerts/notifySuccess',
                notifyError: 'alerts/notifyError',
            }),
            addNewCategory() {
                if(!this.categories.some(
                  data => data.point_of_interest_category_name.toLowerCase().trim() === this.newCategory.point_of_interest_category_name.toLowerCase().trim()
                ))
                {
                  this.$cmsDataProvider.create('createPointOfInterestCategory', { data: this.newCategory}).then((response) => {
                      this.categories.push(response);
                      this.updatePointsOfInterestCategoriesInStore(this.categories);
                      this.newCategory = {};
                      this.addNew = false;
                       this.hasError = false;
                      this.notifySuccess('The category was added successfully');
                  }).catch(() => {
                      this.notifyError('There was an error saving the information');
                  });
                }
                else{
                   this.hasError = true
                }
            },
            removeCategory(category) {
                if (category.is_used) {
                    this.notifyError('The category cannot be deleted, it is used by Points of Interest.');
                    return;
                }

                this.$cmsDataProvider.delete('deletePointOfInterestCategory', { id: category.id_point_of_interest_category}).then(() => {
                    this.refreshCategories();
                    this.updatePointsOfInterestCategoriesInStore(this.categories);
                    this.notifySuccess('The category was removed successfully');
                }).catch(() => {
                    this.notifyError('There was an error saving the information');
                });
            },
            refreshCategories(){
                this.$cmsDataProvider.get('pointsOfInterestCategories').then((response) => {
                this.categories = response;
                this.updatePointsOfInterestCategoriesInStore(response);
                });
            }
        },
        async mounted() {
            this.website = this.getWebsiteInfo
            this.$cmsDataProvider.get('pointsOfInterestCategories').then((response) => {
                this.categories = response;
                this.updatePointsOfInterestCategoriesInStore(response);
            });
        },
    }
</script>

<style scoped>
.category-item .remove-category {
    display: none;
}

.category-item:hover .remove-category {
    display: block;
}
</style>
